import { NavMenuProps } from '@/data/models'

export const navMenu: NavMenuProps[] = [
  {
    label: 'A Maste',
    link: '#quem-somos',
  },
  {
    label: 'Portfólio',
    link: '#portfolio',
  },
  {
    label: 'Simule o valor da sua obra',
    link: '#simular-valor',
  },
]
