import { useState } from 'react'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { ICON } from '../../../../presentation/assets'
import { NavMenuProps } from '../../../../data/models'

import S from './nav-item.module.scss'

const NavItem = ({ label, link, children }: NavMenuProps) => {
  const [animationParent] = useAutoAnimate()
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => setIsOpen(!isOpen)

  return (
    <div ref={animationParent} className={S['single-nav-item']}>
      <a onClick={handleClick} href={link ?? '#'} aria-label={label} className={S['item-link']}>
        <p className={S['item-label']}>
          <span>{label}</span>
          {children && (
            <ICON.AiOutlineClose className={`${S['arrow-icon']} ${isOpen && S['rotate-180']}`} aria-hidden="true" />
          )}
        </p>
      </a>
      {isOpen && children && (
        <div className={S.dropdown} aria-label={`Submenu de ${label}`}>
          {children.map((item, key) => (
            <a key={key} href={item.link ?? '#'} className={S['dropdown-link']} aria-label={item.label}>
              <span className={S['link-label']}>{item.label}</span>
              <div>{item.new && <p className={S.new}>{item.new}</p>}</div>
            </a>
          ))}
        </div>
      )}
    </div>
  )
}

export default NavItem
