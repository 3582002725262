import banheiroEspelho from './banheiro-espelho.webp'
import banheiroQuadro from './banheiro-quadro.webp'
import banheiroVidroQuadro from './banheiro-vidro-quadro.webp'
import banheiro from './banheiro.webp'
import corredor from './corredor.webp'
import cozinhaBancadaCadeiras from './cozinha-bancada-cadeiras.webp'
import cozinhaBancada from './cozinha-bancada.webp'
import cozinhaCorredor from './cozinha-corredor.webp'
import cozinhaMesa from './cozinha-mesa.webp'
import cozinhaTelevisao from './cozinha-televisao.webp'
import cozinhaTotal from './cozinha-total.webp'
import escritorio from './escritorio.webp'
import logoDescricaoImagemBege from './logo-descricao-imagem-bege.png'
import logoDescricaoImagem from './logo-descricao-imagem.png'
import logoDescricao from './logo-descricao.png'
import logoImagem from './logo-imagem.png'
import quadro from './quadro.webp'
import quartoCasal from './quarto-casal.webp'
import quartoCriancaTelevisao from './quarto-crianca-televisao.webp'
import quartoCrianca from './quarto-crianca.webp'
import quarto from './quarto.webp'
import quintalCadeiras from './quintal-cadeiras.webp'
import quintal from './quintal.webp'
import salaDeEstarQuadros from './sala-de-estar-quadros.webp'
import salaDeEstarTelevisao from './sala-de-estar-televisao.webp'
import salaDeEstar from './sala-de-estar.webp'
import header from './header.jpeg'
import casaOrleans1 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (3).jpg'
import casaOrleans2 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (4).jpg'
import casaOrleans3 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (5).jpg'
import casaOrleans4 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (6).jpg'
import casaOrleans5 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (7).jpg'
import casaOrleans6 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (12).jpg'
import casaOrleans7 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (13).jpg'
import casaOrleans8 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (14).jpg'
import casaOrleans9 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (23).jpg'
import casaOrleans10 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (24).jpg'
import casaOrleans11 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (27).jpg'
import casaOrleans12 from './obra-cj/Felipe Medeiros - Casa Orleans - ©Ivan Araújo (30).jpg'
import obraBruna1 from './obra-bruna/HMST9685.jpg'
import obraBruna2 from './obra-bruna/HMST9688.jpg'
import obraBruna3 from './obra-bruna/HMST9715.jpg'
import obraBruna4 from './obra-bruna/HMST9720.jpg'
import obraBruna5 from './obra-bruna/HMST9779.jpg'
import obraBruna6 from './obra-bruna/HMST9807.jpg'
import obraBruna7 from './obra-bruna/HMST9847.jpg'
import obraRf1 from './Obra RF/HMST6906.jpg'
import obraRf2 from './Obra RF/HMST6913.jpg'
import obraRf3 from './Obra RF/HMST6938.jpg'
import obraRf4 from './Obra RF/HMST7039 (1).jpg'
import obraRf5 from './Obra RF/HMST7061.jpg'
import obraRf6 from './Obra RF/HMST7068-HDR.jpg'
import obraRf7 from './Obra RF/HMST7103.jpg'
import obraRf8 from './Obra RF/HMST7108.jpg'
import obraRf9 from './Obra RF/HMST7158.jpg'
import obraRf10 from './Obra RF/HMST7110-HDR.jpg'
import obraRf11 from './Obra RF/HMST7121 (1).jpg'
import obraRf12 from './Obra RF/HMST7161.jpg'
import obraRf13 from './Obra RF/HMST7194.jpg'
import obraRf14 from './Obra RF/HMST7274.jpg'

export const IMAGE = {
  obraRf1,
  obraRf2,
  obraRf3,
  obraRf4,
  obraRf5,
  obraRf6,
  obraRf7,
  obraRf8,
  obraRf9,
  obraRf10,
  obraRf11,
  obraRf12,
  obraRf13,
  obraRf14,
  obraBruna1,
  casaOrleans1,
  casaOrleans2,
  casaOrleans3,
  casaOrleans4,
  casaOrleans5,
  casaOrleans6,
  casaOrleans7,
  casaOrleans8,
  casaOrleans9,
  casaOrleans10,
  casaOrleans11,
  casaOrleans12,
  header,
  banheiroEspelho,
  banheiroQuadro,
  banheiroVidroQuadro,
  banheiro,
  corredor,
  cozinhaBancadaCadeiras,
  cozinhaBancada,
  cozinhaCorredor,
  cozinhaMesa,
  cozinhaTelevisao,
  cozinhaTotal,
  escritorio,
  logoDescricaoImagemBege,
  logoDescricaoImagem,
  logoDescricao,
  logoImagem,
  quadro,
  quartoCasal,
  quartoCriancaTelevisao,
  quartoCrianca,
  quarto,
  quintalCadeiras,
  quintal,
  salaDeEstarQuadros,
  salaDeEstarTelevisao,
  salaDeEstar,
  obraBruna2,
  obraBruna3,
  obraBruna4,
  obraBruna5,
  obraBruna6,
  obraBruna7,
}
