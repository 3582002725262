import { Navbar } from '../../../presentation/components'
import { IMAGE } from '../../../presentation/assets'

import S from './obras.module.scss'

const ObraCjGallery = () => {
  const casaOrleansImages = [
    IMAGE.casaOrleans1,
    IMAGE.casaOrleans2,
    IMAGE.casaOrleans3,
    IMAGE.casaOrleans4,
    IMAGE.casaOrleans5,
    IMAGE.casaOrleans6,
    IMAGE.casaOrleans7,
    IMAGE.casaOrleans8,
    IMAGE.casaOrleans9,
    IMAGE.casaOrleans10,
    IMAGE.casaOrleans11,
    IMAGE.casaOrleans12,
  ]

  return (
    <section className={S.container}>
      <Navbar />
      <h6 className={S.subtitle}>Obras</h6>
      <h1 className={S.title}>Obras CJ</h1>
      <div className={S['box-section']}>
        <div className={S['images-section']}>
          {casaOrleansImages.map((image, index) => (
            <img className={S.img} key={index} src={image} alt={`Casa Orleans ${index + 1}`} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default ObraCjGallery
