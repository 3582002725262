import { ReactNode } from 'react'

import S from './button.module.scss'

export type ButtonProps = {
  typeStyle: 'primary' | 'secondary'
  label: string
  width?: string
  children?: ReactNode
  onClick?: () => void
}

const Button: React.FC<ButtonProps> = ({ typeStyle, label, width, onClick, children }) => {
  const buttonClasses = {
    primary: S.primary,
    secondary: S.secondary,
  }

  const buttonClass = buttonClasses[typeStyle]

  return (
    <button className={`${S.btn} ${buttonClass}`} style={{ width: width || 'auto' }} onClick={onClick}>
      {label} {children}
    </button>
  )
}

export default Button
