export const faq = [
  {
    question: 'O que é a Maste?',
    answer:
      'A Maste é uma empresa especializada em soluções de inteligência para obras, oferecendo tecnologia e serviços para otimizar a gestão de construções, reduzir custos e aumentar a eficiência dos projetos.',
  },
  {
    question: 'Quais serviços a Maste oferece?',
    answer:
      'A Maste oferece serviços como gestão inteligente de projetos de construção, monitoramento em tempo real de obras, planejamento e controle de cronograma, e análise de dados para melhorar a tomada de decisões.',
  },
  {
    question: 'Como a Maste pode ajudar na redução de custos de uma obra?',
    answer:
      'Através do uso de tecnologia avançada e análise de dados, a Maste identifica oportunidades para otimizar recursos, reduzir desperdícios e melhorar a eficiência na execução das atividades, gerando economias significativas no orçamento da obra.',
  },
  {
    question: 'Quais tecnologias são utilizadas pela Maste?',
    answer:
      'A Maste utiliza ferramentas como inteligência artificial, big data, e internet das coisas (IoT) para fornecer insights valiosos e otimizar a gestão de projetos de construção.',
  },
  {
    question: 'A Maste atende obras de quais portes?',
    answer:
      'A Maste oferece soluções para obras de diversos tamanhos, desde pequenas construções até grandes empreendimentos, adaptando seus serviços de acordo com a necessidade específica de cada cliente.',
  },
  {
    question: 'Como funciona o monitoramento em tempo real da Maste?',
    answer:
      'A Maste utiliza sensores e softwares especializados para acompanhar a execução da obra em tempo real, permitindo o acompanhamento de todas as etapas do projeto e garantindo maior controle e transparência.',
  },
  {
    question: 'Como a Maste garante o cumprimento do cronograma de obras?',
    answer:
      'Com o uso de ferramentas de planejamento e controle, a Maste acompanha cada etapa do projeto, permitindo ajustes em tempo real para garantir que os prazos sejam cumpridos de acordo com o cronograma estabelecido.',
  },
]
