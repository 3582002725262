import { useState } from 'react'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Link as ScrollLink } from 'react-scroll'

import NavMobile from './nav-mobile'
import { IMAGE, ICON } from '../../../../presentation/assets'
import { IconWithProps } from '../../../../utils'
import { navMenu } from '../../../../data/ui'

import S from './navbar.module.scss'

export default function Navbar() {
  const [animationParent] = useAutoAnimate()
  const [isSideMenuOpen, setSideMenu] = useState(false)

  return (
    <nav className={S.navbar} aria-label="Navegação Principal">
      <section ref={animationParent} className={S['left-section']} aria-label="Seção de Navegação Esquerda">
        <a aria-label="Logo Maste" href="/">
          <img src={IMAGE.logoDescricao} alt="Logo Maste" className={S.logo} />
        </a>

        {isSideMenuOpen && <NavMobile closeSideMenu={() => setSideMenu(false)} />}

        <div className={S['nav-items']} aria-label="Itens de Navegação">
          {navMenu.map((item, index) => (
            <div key={index} className={S['nav-link']}>
              <ScrollLink
                to={item.link.replace('#', '')}
                smooth={true}
                duration={500}
                className={S['link-text']}
                aria-label={item.label}
              >
                <span>{item.label}</span>
              </ScrollLink>
            </div>
          ))}
        </div>
      </section>

      <aside className={S['right-section']} aria-label="Seção de Navegação Direita">
        <ICON.HiOutlineChatBubbleBottomCenterText className={S.icon} aria-hidden="true" />
        <a
          href="https://api.whatsapp.com/send?phone=5547988158511&text=Olá!%20Gostaria%20de%20saber%20mais%20sobre%20os%20serviços%20oferecidos.%20Poderiam%20me%20enviar%20um%20orçamento?"
          className={S.link}
          aria-label="Fale com a gente"
        >
          <span>Fale com a gente</span>
        </a>
      </aside>

      <IconWithProps onClick={() => setSideMenu(true)} className={S['menu-icon']} aria-label="Abrir Menu">
        <ICON.RxTextAlignRight aria-hidden="true" />
      </IconWithProps>
    </nav>
  )
}
