import NavItem from '../../../../presentation/components/common/navbar/nav-item'
import { NavMenuMobileProps } from '../../../../data/models'
import { ICON } from '../../../../presentation/assets'
import { IconWithProps } from '../../../../utils'
import { navMenu } from '../../../../data/ui'

import S from './nav-mobile.module.scss'

const NavMobile = ({ closeSideMenu }: NavMenuMobileProps) => {
  return (
    <div className={S['mobile-nav-container']} aria-label="Navegação Móvel">
      <nav className={S['mobile-nav']} aria-label="Menu de Navegação Móvel">
        <section className={S['close-section']}>
          <IconWithProps onClick={closeSideMenu} className={S['close-icon']} aria-label="Fechar Menu">
            <ICON.AiOutlineClose aria-hidden="true" />
          </IconWithProps>
        </section>
        <div className={S['nav-items']} aria-label="Itens de Navegação">
          {navMenu.map((item, key) => (
            <NavItem key={key} label={item.label} new={item.new} link={item.link} aria-label={item.label}>
              {item.children}
            </NavItem>
          ))}
        </div>
        <section className={S['right-buttons']} aria-label="Botões de Autenticação">
          <a href="https://api.whatsapp.com/send?phone=5547988158511&text=Olá!%20Gostaria%20de%20saber%20mais%20sobre%20os%20serviços%20oferecidos.%20Poderiam%20me%20enviar%20um%20orçamento?">
            <a className={S.link} aria-label="Fale com a gente">
              <span>Fale com a gente</span>
            </a>
          </a>
          <ICON.HiOutlineChatBubbleBottomCenterText className={S.icon} aria-hidden="true" />
        </section>
      </nav>
    </div>
  )
}

export default NavMobile
