import { FaCalendarAlt, FaDollarSign, FaHandshake, FaHardHat } from 'react-icons/fa'

import S from './differentials.module.scss'

const differentialsItems = [
  {
    icon: <FaCalendarAlt />,
    number: '01',
    title: 'AGENDAMENTO',
    subtitle: 'De forma simples, você entra em contato e agendamos uma reunião para conhecer o projeto.',
  },
  {
    icon: <FaDollarSign />,
    number: '02',
    title: 'ORÇAMENTO',
    subtitle: 'Preparamos um orçamento detalhado, cobrindo todos os aspectos e a gestão necessária para o seu projeto.',
  },
  {
    icon: <FaHandshake />,
    number: '03',
    title: 'ALINHAMENTO',
    subtitle: 'Realizamos uma conversa para ajustar e definir os detalhes da execução do projeto.',
  },
  {
    icon: <FaHardHat />,
    number: '04',
    title: 'INÍCIO DA OBRA',
    subtitle: 'Com tudo acertado, começamos a obra, colocando o projeto em prática.',
  },
]

const Differentials = () => {
  return (
    <section id="processo" className={S.container}>
      <div className={S['title-section']}>
        <h3 className={S.subtitle}>ENTENDA O PROCESSO</h3>
        <h1 className={S.title}>
          Em poucos passos, ajudamos você a fazer <br /> a gestão de obra de forma eficiente
        </h1>
      </div>
      <div className={S['differentials-section']}>
        {differentialsItems.map((item, index) => (
          <div className={S.box} key={index}>
            <div className={S['header-section']}>
              <div className={S.icon}>{item.icon}</div>
              <h1 className={S.number}>{item.number}</h1>
            </div>
            <h1 className={S.title}>{item.title}</h1>
            <h3 className={S.subtitle}>{item.subtitle}</h3>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Differentials
