import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { makeHome } from './main/factories/pages'
import ObrasBruna from './presentation/pages/obras/obras-bruna'
import ObrasCf from './presentation/pages/obras/obras-cj'
import ObrasRf from './presentation/pages/obras/obras-rf'

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={makeHome()} />
        <Route path="/obras-bruna" element={<ObrasBruna />} />
        <Route path="/obras-cj" element={<ObrasCf />} />
        <Route path="/obras-rf" element={<ObrasRf />} />
        <Route path="*" element={makeHome()} />
      </Routes>
    </Router>
  )
}

export default App
