import { Navbar } from '../../../presentation/components'

import { IMAGE } from '../../../presentation/assets'

import S from './obras.module.scss'

const ObraRfGallery = () => {
  const obraRfImages = [
    IMAGE.obraRf1,
    IMAGE.obraRf2,
    IMAGE.obraRf3,
    IMAGE.obraRf4,
    IMAGE.obraRf5,
    IMAGE.obraRf6,
    IMAGE.obraRf7,
    IMAGE.obraRf8,
    IMAGE.obraRf9,
    IMAGE.obraRf10,
    IMAGE.obraRf11,
    IMAGE.obraRf12,
    IMAGE.obraRf13,
    IMAGE.obraRf14,
  ]

  return (
    <section className={S.container}>
      <Navbar />
      <h6 className={S.subtitle}>Obras</h6>
      <h1 className={S.title}>Obras RF</h1>
      <div className={S['box-section']}>
        <div className={S['images-section']}>
          {obraRfImages.map((image, index) => (
            <img className={S.img} key={index} src={image} alt={`Obra RF ${index + 1}`} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default ObraRfGallery
