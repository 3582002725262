import { IMAGE } from '../../../assets'

import {
  FaLightbulb,
  FaChartLine,
  FaShieldAlt,
  FaTools,
  FaUsers,
  FaClock,
  FaDollarSign,
  FaCogs,
  FaFileContract,
  FaRecycle,
  FaSignal,
  FaHandshake,
} from 'react-icons/fa'

import S from './benefits.module.scss'

const benefitsItems = [
  {
    icon: FaLightbulb,
    title: 'Inovação em Cada Etapa',
    subtitle:
      'Utilizamos tecnologia de ponta para garantir que sua obra seja realizada com o máximo de eficiência e inovação.',
  },
  {
    icon: FaChartLine,
    title: 'Gestão Eficiente',
    subtitle:
      'Nossas ferramentas ajudam a otimizar processos, reduzindo desperdícios e aumentando a produtividade em suas obras.',
  },
  {
    icon: FaShieldAlt,
    title: 'Segurança Garantida',
    subtitle:
      'A Maste prioriza a segurança em todas as etapas do projeto, com soluções que mitigam riscos e protegem seus ativos.',
  },
  {
    icon: FaTools,
    title: 'Tecnologia Avançada',
    subtitle:
      'Implementamos as melhores tecnologias para monitoramento, análise e controle de obras, garantindo qualidade e precisão.',
  },
  {
    icon: FaUsers,
    title: 'Equipe Especializada',
    subtitle:
      'Contamos com uma equipe de especialistas prontos para oferecer suporte técnico e consultoria personalizada.',
  },
  {
    icon: FaClock,
    title: 'Entrega no Prazo',
    subtitle:
      'Com a Maste, sua obra é gerenciada de forma a cumprir prazos rigorosos, evitando atrasos e custos extras.',
  },
  {
    icon: FaDollarSign,
    title: 'Redução de Custos',
    subtitle: 'Nossa abordagem inteligente permite uma gestão financeira eficiente, reduzindo gastos desnecessários.',
  },
  {
    icon: FaCogs,
    title: 'Automação de Processos',
    subtitle: 'Automatizamos processos complexos, simplificando a gestão e aumentando a produtividade do seu projeto.',
  },
  {
    icon: FaFileContract,
    title: 'Contratos Transparente',
    subtitle:
      'Nossos contratos são claros e justos, garantindo que todas as partes estejam protegidas e cientes de seus direitos.',
  },
  {
    icon: FaRecycle,
    title: 'Sustentabilidade',
    subtitle:
      'Priorizamos práticas sustentáveis em nossas obras, contribuindo para um futuro mais verde e responsável.',
  },
  {
    icon: FaSignal,
    title: 'Monitoramento em Tempo Real',
    subtitle:
      'Oferecemos soluções de monitoramento em tempo real, permitindo ajustes rápidos e informados durante o processo.',
  },
  {
    icon: FaHandshake,
    title: 'Parceria Confiável',
    subtitle: 'Somos parceiros de confiança, comprometidos em garantir o sucesso do seu projeto do início ao fim.',
  },
]

const Benefits = () => {
  return (
    <section className={S.container} id="beneficios">
      <div className={S['image-box']}>
        <img className={S.img} src={IMAGE.quintalCadeiras} alt="Empresa Maste" />
        <h1 className={S.title}>Onde há construção, há Maste.</h1>
      </div>
      <div className={S['content-section']}>
        <h1 className={S.title}> Por que escolher a Maste?</h1>
        {benefitsItems.map((item, index) => (
          <div className={S.box} key={index}>
            <div className={S['box-icon']}>
              <item.icon className={S.icon} />
            </div>
            <h1 className={S['title-benefits']}>{item.title}</h1>
            <p className={S['subtitle-benefits']}>{item.subtitle}</p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Benefits
