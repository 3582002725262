import { Benefits, Contact, Differentials, Faq, Footer, Header, Navbar } from '../../../presentation/components'
import { faq } from '../../../data/ui'
import Carousel from '../../../presentation/components/common/carousel/carousel'
import { InfoAbout } from '../../../presentation/components/layout/ui'

const Home = () => {
  return (
    <>
      <Navbar />
      <Header />
      <Differentials />
      <InfoAbout />
      <Carousel />
      <Benefits />
      <Faq items={faq} title="Dúvidas sobre as soluções da Maste?" />
      <Contact />
      <Footer />
    </>
  )
}

export default Home
