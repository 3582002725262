import { Link as LinkScroll } from 'react-scroll'

import { HiArrowDown } from 'react-icons/hi'

import { IMAGE } from '../../../../assets'
import { Button } from '../../../form'

import S from './info-about.module.scss'

const InfoAbout = () => {
  return (
    <section id="quem-somos" className={S.container}>
      <div className={S['images-section']}>
        <img className={S['image-primary']} src={IMAGE.escritorio} alt="Empresa Maste" />
        <img className={S['image-secondary']} src={IMAGE.salaDeEstarQuadros} alt="Empresa Maste" />
      </div>
      <div className={S['about-section']}>
        <div className={S['description-section']}>
          <p className={S.subtitle}>Nosso jeito</p>
          <h1 className={S.title}>Tudo o que você precisa em um só lugar</h1>
          <p className={S.paragraph}>
            Na Maste, oferecemos soluções completas para obras e reformas, unindo qualidade, inovação e eficiência em
            cada projeto. Nosso objetivo é transformar espaços com planejamento estratégico e execução impecável,
            atendendo tanto a construções residenciais quanto comerciais. Com uma equipe de especialistas comprometidos
            em cada detalhe, garantimos que sua obra seja concluída com excelência, dentro do prazo e orçamento. Na
            Maste, cada projeto é uma oportunidade de fazer a diferença, entregando resultados que superam as
            expectativas.
          </p>
        </div>
        <LinkScroll to="beneficios" smooth={true} duration={500}>
          <Button typeStyle="secondary" width="200px" label="Benefícios">
            <HiArrowDown className={S.icon} />
          </Button>
        </LinkScroll>
      </div>
    </section>
  )
}

export default InfoAbout
