import { FaInstagram, FaWhatsapp } from 'react-icons/fa'

import S from './footer.module.scss'

const Footer = () => {
  return (
    <footer className={S.container}>
      <div className={S['title-section']}>
        <h1 className={S.title}>© 2024 Maste. Todos os direitos reservados</h1>
      </div>
      <div className={S['icons-section']}>
        <a href="https://www.instagram.com/maste.joinville/" target="_blank" rel="noopener noreferrer">
          <FaInstagram className={S.icon} />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=5547988158511&text=Olá!%20Gostaria%20de%20saber%20mais%20sobre%20os%20serviços%20oferecidos.%20Poderiam%20me%20enviar%20um%20orçamento?"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className={S.icon} />
        </a>
      </div>
    </footer>
  )
}

export default Footer
