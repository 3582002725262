import { Navbar } from '../../../presentation/components'

import { IMAGE } from '../../../presentation/assets'

import S from './obras.module.scss'

const ObraBrunaGallery = () => {
  const obraBrunaImages = [
    IMAGE.obraBruna1,
    IMAGE.obraBruna2,
    IMAGE.obraBruna3,
    IMAGE.obraBruna4,
    IMAGE.obraBruna5,
    IMAGE.obraBruna6,
    IMAGE.obraBruna7,
  ]

  return (
    <section className={S.container}>
      <Navbar />
      <h6 className={S.subtitle}>Obras</h6>
      <h1 className={S.title}>Obras Bruna Elisa</h1>
      <div className={S['box-section']}>
        <div className={S['images-section']}>
          {obraBrunaImages.map((image, index) => (
            <img className={S.img} key={index} src={image} alt={`Obra Bruna ${index + 1}`} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default ObraBrunaGallery
