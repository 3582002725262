import { useEffect, useRef } from 'react'
import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import S from './carousel.module.scss'
import { IMAGE } from '../../../../presentation/assets'

const imageItems = [
  { name: 'Obras Bruna Elisa', image: IMAGE.obraBruna1, url: '/obras-bruna' },
  { name: 'Obras Rf', image: IMAGE.obraRf5, url: '/obras-rf' },
  { name: 'Obras Cj', image: IMAGE.casaOrleans1, url: '/obras-cj' },
]

const Carousel = () => {
  const carouselRef = useRef(null)

  useEffect(() => {
    if (carouselRef.current) {
      const splideOptionBanner = {
        type: 'loop',
        autoplay: false,
        rewind: true,
        rewindByDrag: true,
        pagination: false,
        arrows: false,
        interval: 5000,
        speed: 1000,
        perPage: 4,
        perMove: 1,
        focus: 'center' as const,
        slideFocus: true,
        updateOnMove: true,
        clones: 2,
        gap: '20px',
        breakpoints: {
          1468: {
            perPage: 3,
            arrows: false,
          },
          1268: {
            perPage: 2,
            arrows: false,
          },
          768: {
            perPage: 1,
            arrows: false,
          },
        },
      }

      new Splide(carouselRef.current, splideOptionBanner).mount()
    }
  }, [])

  return (
    <section
      id="portfolio"
      className={`${S['container']} js-carousel-01 splide topics-carousel`}
      aria-label="slider"
      ref={carouselRef}
    >
      <h6 className={S['subtitle']}>ARRASTE PRO LADO</h6>
      <h1 className={S['title']}>Conheça nosso portfólio</h1>
      <div className="topics-carousel-track splide__track">
        <div className="splide__list">
          {imageItems.map((item, index) => (
            <div key={index} className="topics-carousel-slide splide__slide">
              <div className={S['slide-container']}>
                <img className={S.img} src={item.image} alt={`Imagem do portfólio: ${item.name}`} />
                <a href={item.url}>
                  <button className={S['view-button']}>Ver projeto</button>
                </a>
              </div>
              <h1 className={S['title-image']}>{item.name}</h1>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Carousel
