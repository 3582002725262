import React from 'react'
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import emailjs from 'emailjs-com'
import { Button } from '../../form'
import S from './contact.module.scss'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Define o schema de validação
const schema = z.object({
  name: z.string().min(1, 'Nome é obrigatório'),
  email: z.string().email('Email inválido').nonempty('Email é obrigatório'),
  obraLocation: z.string().min(1, 'Selecione onde será realizada a obra'),
  comodos: z.array(z.object({ name: z.string().min(1, 'Selecione os cômodos') })),
  descricao: z.string().min(1, 'Descreva o que precisa'),
})

// Define o tipo dos dados do formulário com Zod
type FormData = z.infer<typeof schema>

// Define o tipo específico dos cômodos
type ComodosProps = {
  name: string
}

const Contact: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const { fields, append, remove } = useFieldArray<FormData>({
    control,
    name: 'comodos',
    keyName: 'id',
  })

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    console.log(data)
    const templateParams = {
      to_name: data.name,
      from_name: data.email,
      obraLocation: data.obraLocation,
      comodos: data.comodos.map((comodo) => comodo.name).join(', '),
      descricao: data.descricao,
    }

    try {
      await emailjs.send('service_nqdfdwl', 'template_jwzwco8', templateParams, '_b_Fj1noBh1bNpDOl')
      toast.success('Mensagem enviada com sucesso!')
      reset()
    } catch (error) {
      console.error('FAILED...', error)
      toast.error('Falha ao enviar a mensagem. Tente novamente.')
    }
  }

  return (
    <section id="simular-valor" className={S.container}>
      <ToastContainer />
      <div className={S['text-section']}>
        <p className={S['subtitle']}>Contate-nos</p>
        <h1 className={S['title']}>Entre em contato conosco</h1>
        <p className={S['subtitle']}>Vamos encontrar a melhor solução para o seu projeto?</p>
        <h1 className={S['paragraph']}>Você também pode nos escrever um email:</h1>
        <p className={S['paragraph']}>contato@masteobras.com</p>
      </div>
      <form className={S['form-section']} onSubmit={handleSubmit(onSubmit)}>
        <div className={S['input-group']}>
          <input type="text" {...register('name')} className={S.input} placeholder="Nome Completo" />
          {errors.name && <span>{errors.name.message}</span>}
        </div>
        <div className={S['input-group']}>
          <input type="email" {...register('email')} className={S.input} placeholder="Email" />
          {errors.email && <span>{errors.email.message}</span>}
        </div>
        <div className={S['input-group']}>
          <select {...register('obraLocation')} className={S.input}>
            <option value="">Selecione onde será realizada a obra</option>
            <option value="local1">Casa</option>
            <option value="local2">Apartamento</option>
            <option value="local3">Imóvel Comercial</option>
          </select>
          {errors.obraLocation && <span>{errors.obraLocation.message}</span>}
        </div>
        <div className={S['input-group']}>
          <h2>Selecione os cômodos:</h2>
          {fields.map((item, index) => (
            <div key={item.id} className={S['input-group']}>
              <select {...register(`comodos.${index}.name`)} className={S.input}>
                <option value="">Selecione um cômodo</option>
                <option value="comodo1">Quarto</option>
                <option value="comodo2">Sala de Estar</option>
                <option value="comodo3">Sala de Jantar</option>
                <option value="comodo4">Sala de TV</option>
                <option value="comodo5">Home Office</option>
                <option value="comodo6">Quarto do Bebê</option>
                <option value="comodo7">Banheiro</option>
                <option value="comodo8">Cozinha</option>
                <option value="comodo9">Sala Comercial</option>
                <option value="comodo10">Área Externa</option>
              </select>
              {errors.comodos?.[index]?.name && <span>{errors.comodos[index]?.name?.message}</span>}
              <button type="button" onClick={() => remove(index)}>
                Remover
              </button>
            </div>
          ))}
          <button type="button" onClick={() => append({ name: '' })}>
            Adicionar Cômodo
          </button>
        </div>
        <div className={S['input-group']}>
          <input type="text" {...register('descricao')} className={S.input} placeholder="Descreva o que precisa" />
          {errors.descricao && <span>{errors.descricao.message}</span>}
        </div>
        <Button typeStyle="secondary" label="SOLICITAR ORÇAMENTO" />
      </form>
    </section>
  )
}

export default Contact
