'use client'

import { useState } from 'react'

import { FaqProps } from '../../../../data/models'

import S from './faq.module.scss'

const Faq: React.FC<FaqProps> = ({ items, title }) => {
  const [faqs, setFaqs] = useState(items ? items.map((faq) => ({ ...faq, open: false })) : [])
  const [showAll, ] = useState(false)

  const toggleFAQ = (index: number) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) => ({
        ...faq,
        open: i === index ? !faq.open : faq.open,
      }))
    )
  }

  const visibleFaqs = showAll ? faqs : faqs.slice(0, 5)

  return (
    <section className={S.container} aria-labelledby="faq-titulo">
      <h3 className={S.subtitle}>Perguntas Frequentes</h3>
      <h1 className={S.title}>{title}</h1>
      {visibleFaqs.map((faq, index) => (
        <div
          className={`${S['faq-section']} ${faq.open ? S.open : ''}`}
          key={index}
          onClick={() => toggleFAQ(index)}
          aria-expanded={faq.open}
          role="button"
          tabIndex={0}
        >
          <div className={S['faq-question']}>{faq.question}</div>
          {faq.open && (
            <div className={S['faq-answer']} aria-hidden={!faq.open}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
      {!showAll && faqs.length > 5 && <div className={S['btn-section']}></div>}
    </section>
  )
}

export default Faq
