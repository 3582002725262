import { Link as ScrollLink } from 'react-scroll'

import { Button } from '../../../../presentation/components/form'

import { HiArrowDown } from 'react-icons/hi'

import S from './header.module.scss'

const Header = () => {
  return (
    <header className={S.container}>
      <div className={S['title-section']}>
        <h1 className={S.title}>
          Seu foco é viver bem,
          <br /> o nosso é construir
          <br /> por você!
        </h1>
        <ScrollLink to="processo" smooth={true} duration={500}>
          <Button typeStyle="primary" label="Entenda o processo">
            <HiArrowDown className={S.icon} />
          </Button>
        </ScrollLink>
      </div>
      <div className={S['overlay-section']}>
        <h6 className={S.title}>
          Vamos trabalhar <br /> juntos <span className={S.linha}></span>
        </h6>
        <a
          href="https://api.whatsapp.com/send?phone=5547988158511&text=Olá!%20Gostaria%20de%20saber%20mais%20sobre%20os%20serviços%20oferecidos.%20Poderiam%20me%20enviar%20um%20orçamento?"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={S.subtitle}>Fale Conosco</span>
        </a>
      </div>
    </header>
  )
}

export default Header
